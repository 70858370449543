'use client'
import React from 'react'
import { z } from 'zod'
import { getEnvKey } from '@/lib/env'
import { IconButton } from '@/lib/tailwind/Button'

const API_KEY = getEnvKey('NEXT_PUBLIC_GETADDRESS_API_KEY')

const SuggestionSchema = z.object({
  id: z.string(),
  address: z.string(),
})

const SuggestionResponseSchema = z.object({
  suggestions: z.array(SuggestionSchema),
})

export type Suggestion = z.infer<typeof SuggestionSchema>

async function getSuggestions(term: string): Promise<Array<Suggestion>> {
  const response = await fetch(`https://api.getAddress.io/autocomplete/${term}?api-key=${API_KEY}`)

  if (!response.ok) {
    throw new Error('Something went wrong, please try again later!')
  }

  let json: unknown
  try {
    json = await response.json()
  } catch {
    throw new Error('Something went wrong, please try again later!')
  }

  const suggestionsResponse = SuggestionResponseSchema.parse(json)

  return suggestionsResponse.suggestions
}

const GetAddressSchema = z.object({
  formatted_address: z.array(z.string()),
  line_1: z.string(),
  line_2: z.string(),
  postcode: z.string(),
  latitude: z.number(),
  longitude: z.number(),
})

type GetAddress = z.infer<typeof GetAddressSchema>

async function getGetAddress(id: string): Promise<GetAddress> {
  const response = await fetch(`https://api.getAddress.io/get/${id}?api-key=${API_KEY}`)

  if (!response.ok) {
    throw new Error('Something went wrong, please try again later!')
  }

  let json: unknown
  try {
    json = await response.json()
  } catch {
    throw new Error('Something went wrong, please try again later!')
  }

  return GetAddressSchema.parse(json)
}

export type Address = {
  address: string
  line1: string
  line2: string | null
  postcode: string
  latitude: number
  longitude: number
}

export async function getAddress(id: string): Promise<Address> {
  const {
    postcode,
    latitude,
    longitude,
    formatted_address,
    line_1,
    line_2,
  } = await getGetAddress(id)
  return {
    address: formatted_address.filter(v => v !== '').join(', '),
    postcode,
    latitude,
    longitude,
    line1: line_1,
    line2: line_2 !== '' ? line_2 : null,
  }
}

export default function AddressAutocomplete({
  setValue,
  cancel,
  placeholder,
}: {
  setValue: (value: Suggestion | null) => void
  cancel: () => void
  placeholder?: string
}): React.ReactNode {
  const ref = React.useRef<HTMLDivElement>(null)
  const [open, setOpen] = React.useState(false)
  const [loading, setLoading] = React.useState<boolean>(false)
  const [inputValue, setInputValue] = React.useState<string>('')
  const [options, setOptions] = React.useState<Array<Suggestion>>([])

  React.useEffect(() => {
    if (inputValue !== '') {
      setLoading(true)
      const debouncedGetSuggestion = setTimeout(() => {
        getSuggestions(inputValue)
          .then((v) => {
            setOptions(v)
            setLoading(false)
          })
          .catch(() => {
            setLoading(false)
          })
      }, 500)

      return () => clearTimeout(debouncedGetSuggestion)
    } else {
      setOptions([])
    }
  }, [inputValue])

  function onSelect(suggestion: Suggestion): void {
    setInputValue(suggestion.address)
    setValue(suggestion)
  }

  function onClickX(): void {
    setInputValue('')
    setOpen(false)
  }

  React.useEffect(() => {
    const listener = (event: Event) => {
      if (ref.current && !ref.current.contains(event.target as Node)) {
        setOpen(false)
      }
    }
    document.addEventListener('click', listener)

    return () => document.removeEventListener('click', listener)
  })

  return (
    <div className="rounded-lg border-2 border-outline w-[300px] sm:w-[380px] lg:w-[300px] xl:w-[380px] absolute bg-white z-10" ref={ref}>
      <div className="flex flex-row gap-4 rounded-lg items-center w-full h-full px-4">
        <IconButton onClick={cancel} icon="leftArrowDark" />
        <input
          type="text"
          onChange={e => setInputValue(e.currentTarget.value)}
          value={inputValue}
          className="h-full w-0 flex-1 outline-none"
          placeholder={placeholder}
          onClick={() => setOpen(true)}
        />
        <IconButton onClick={onClickX} icon="close" />
      </div>
      {open && (
        options.length > 0
          ? (
            <ul className="max-h-80 overflow-auto">
              {options.map(option => (
                <li key={option.id} onClick={() => onSelect(option)} className="cursor-pointer hover:text-faded py-2 px-5">
                  {option.address}
                </li>
              ))}
            </ul>
            )
          : loading
            ? <div className="py-2 px-5"> Loading...</div>
            : <div className="py-2 px-5">No options</div>
      )}
    </div>
  )
}
