import Icon from '@/lib/Icon'

export const CLASS = 'bg-brand text-white rounded-lg outline outline-outline outline-2 p-2 text-center flex flex-row gap-2 justify-center'

export default function Button({
  onClick,
  children,
  className,
  disabled,
}: {
  onClick: () => void
  children: React.ReactNode
  className?: string
  disabled?: boolean
}): React.ReactNode {
  return (
    <button
      onClick={onClick}
      className={`${CLASS} ${className ?? ''} disabled:cursor-not-allowed disabled:bg-brandFaded`}
      disabled={disabled === true}
    >
      {children}
    </button>
  )
}

export function IconButton({
  onClick,
  icon,
}: {
  onClick: () => void
  icon: React.ComponentProps<typeof Icon>['icon']
}): React.ReactNode {
  return (
    <button onClick={onClick} className="w-[24px] shrink-0 py-3">
      <Icon icon={icon} width={24} height={24} />
    </button>
  )
}
