'use client'

import React from 'react'
import { z } from 'zod'
import Link from 'next/link'
import { CitySchema } from '@/lib/cities'
import useLocalStorage from '@/lib/useLocalStorage'
import PropertyImage from '@/lib/PropertyImage'
import { Search } from '@prisma/client'
import { getSearch, saveSearch } from './calculator/actions'
import { DEFAULT_LOCATION_INPUTS } from '../default-location'
import AddressAutocomplete, { Address, getAddress, Suggestion } from '@/lib/tailwind/AddressAutocomplete'
import Button, { IconButton, CLASS as buttonClass } from '@/lib/tailwind/Button'
import { sendGTMEvent } from '@next/third-parties/google'
import { CalculatorLink } from '@/lib/tailwind/CalculatorBlock'

const SearchParamsSchema = z
  .object({
    city: CitySchema,
  })
  .partial()

export default function Home({
  searchParams,
}: {
  searchParams?: unknown
}) {
  const { value: searchId, setValue: setSearchId, ready: searchIdReady } = useLocalStorage('search-id')
  const [loading, setLoading] = React.useState(true)
  const [installersParam, setInstallersParam] = React.useState<string>(
    getInstallerParams(searchId, searchParams)
  )
  const [search, setSearch] = React.useState<Search | null>(null)

  React.useEffect(() => {
    if (!searchIdReady) {
      return
    }

    setInstallersParam(getInstallerParams(searchId, searchParams))

    if (searchId === null) {
      setLoading(false)
      return
    }

    getSearch(searchId).then(
      (search) => {
        if (search !== null) {
          setSearch(search)
        }
        setLoading(false)
      },
      (error) => {
        console.error(error)
        setLoading(false)
      },
    )
  }, [searchId, searchIdReady, searchParams])

  async function onAddressChange(address: Address) {
    setLoading(true)

    try {
      sendGTMEvent({ event: 'select_content', content_type: 'address_search', content_id: address.postcode })
    } catch (e) {
      console.error('Failed to send GA event', e)
    }

    const search = await saveSearch(address)

    setSearchId(search.id)
    setSearch(search)
    setLoading(false)
  }

  return (
    <div className="w-full flex flex-col">
      <div className="flex flex-col items-center bg-[linear-gradient(to_bottom,#1A6AFF_0%,#1A6AFF_77px,#F4F3ED_77px,#F4F3ED_100%)]">
        <div className="max-w-screen-sm flex flex-col items-center p-8 md:px-0 gap-3 w-full">
          <PropertyBlock search={search} onAddressChange={onAddressChange} ready={!loading} />

          <h2>
            Discover savings by changing...
          </h2>
          <div className="grid gap-3 grid-cols-2 justify-items-stretch w-full">
            <CalculatorLink
              calculator="fabric"
              savings={!searchIdReady || searchId != null ? null : 811}
            />
            <CalculatorLink
              calculator="heating"
              savings={!searchIdReady || searchId != null ? null : 137}
            />
            <CalculatorLink
              calculator="usage"
              savings={!searchIdReady || searchId != null ? null : 451}
            />
            <CalculatorLink
              calculator="solar"
              savings={!searchIdReady || searchId != null ? null : 630}
            />
          </div>

          <h2>
            Ready to make changes?
          </h2>
          <Link
            href={`/installers${installersParam}`}
            className={`${buttonClass} no-underline text-2xl min-w-[90%]`}
          >
            Find a local installer
          </Link>
        </div>
      </div>

      <div className="bg-brand text-white flex justify-center">
        <div className="max-w-screen-sm py-4 px-8 sm:px-0">
          <h2 className="text-2xl py-8 font-semibold -tracking-[2%]">
            Orang Energy helps to reduce your energy bills!
          </h2>
          <div className="flex flex-col gap-5">
            <p>
              Our energy savings calculator shows easy ways to lower your energy costs, such as draught-proofing or making simple changes to your daily routine.
            </p>
            <p>
              You can also explore property upgrades, like adding loft insulation or installing a heat pump.
              These upgrades not only save you money every month but also increase the value of your home.
            </p>
            <h3 className="border-y border-white py-4 text-xl">
              See your results in seconds - completely free.
              No sign up needed!
            </h3>
            <p>
              We’ve partnered with industry experts to help create a detailed model of your home, using data from your home’s EPC alongside hourly regional climate data.
            </p>
            <p>
              For each upgrade you add, the calculator shows the estimated annual energy savings and a ballpark cost for the work based on your property details.
            </p>
            <p>
              Next, we provide a list of qualified local installers who can provide you with a detailed quote and bring your changes to life.
            </p>
            <p>
              Ready to save money?
              Try Orang Energy now!
            </p>
          </div>
        </div>
      </div>
    </div>
  )
}

function PropertyBlock({
  ready,
  search,
  onAddressChange,
}: {
  ready: boolean
  search: Search | null
  onAddressChange: (_: Address) => Promise<void>
}): React.ReactNode {
  const [expanded, setExpanded] = React.useState<boolean>(false)
  const address = search?.address ?? DEFAULT_LOCATION_INPUTS.address
  const postcode = search?.postcode ?? DEFAULT_LOCATION_INPUTS.postcode

  async function handleSelectSuggestion(suggestion: Suggestion | null) {
    setExpanded(false)
    if (suggestion != null) {
      try {
        const address = await getAddress(suggestion.id)
        await onAddressChange(address)
      } catch (e) {
        return
      }
    }
  }

  if (!ready) {
    return (
      <div
        className="border-outline border-2 rounded-lg overflow-hidden w-full h-auto"
      >
        <div className="flex justify-center items-center bg-white w-full h-[164px]">
          Loading...
        </div>
      </div>
    )
  }

  return (
    <div className="relative w-full">
      <div className="flex flex-col rounded-lg border-2 border-outline w-full overflow-hidden">
        <div className="h-[82px] w-full bg-white">
          <PropertyImage
            address={address}
            postcode={postcode}
            width={480}
            height={180}
          />
        </div>
        <div className={`h-[82px] w-full bg-white flex justify-center ${expanded ? 'pt-[15px]' : ''}`}>
          {!expanded
            ? (
              <div className="flex flex-row gap-4 items-center rounded-lg border-2 border-outline px-2 w-[90%] self-center">
                <div className="flex flex-row items-left gap-0 min-width-0 overflow-hidden w-full ml-4 py-3">
                  <p className="truncate">
                    {address}
                    ,
                  </p>
                  <p className="whitespace-nowrap">
                    &nbsp;
                    {postcode}
                  </p>
                </div>
                {search !== null
                  ? (
                    <div className="mr-2">
                      <IconButton onClick={() => setExpanded(true)} icon="close" />
                    </div>
                    )
                  : (
                    <Button onClick={() => setExpanded(true)} className="text-sm">
                      Change
                    </Button>
                    )}
              </div>
              )
            : (
              <AddressAutocomplete
                setValue={handleSelectSuggestion}
                placeholder="Type your address"
                cancel={() => setExpanded(false)}
              />
              )}
        </div>
      </div>
    </div>
  )
}

function getInstallerParams(searchId: string | null, searchParams: unknown): string {
  const searchParamsParseResult = SearchParamsSchema.safeParse(searchParams)
  return searchId != null
    ? `?searchId=${searchId}`
    : searchParamsParseResult.data?.city != null
      ? `?city=${searchParamsParseResult.data.city}`
      : ''
}
