import React from 'react'
import Link from 'next/link'
import { formatNumber } from '@/lib/formatters'
import Icon from '@/lib/Icon'
import type { CalculatorBlockId } from '@/app/(tailwind)/calculator/BlockGrid'

type CalculatorProperties = {
  icon: React.ComponentProps<typeof Icon>['icon']
  background: string
  title: string
}

export const CALCULATOR_PROPERTIES: { [_ in CalculatorBlockId]: CalculatorProperties } = {
  fabric: {
    icon: 'houseOutline',
    background: 'bg-brown',
    title: 'Insulation',
  },
  heating: {

    icon: 'radiatorOutline',
    background: 'bg-blue',
    title: 'Heating system',
  },
  usage: {

    icon: 'routine',
    background: 'bg-pink',
    title: 'Daily routine',
  },
  solar: {

    icon: 'solar',
    background: 'bg-green',
    title: 'Solar & battery',
  },
}

const OUTER_CLASS = 'no-underline rounded-lg border-outline border-2 overflow-hidden'

export function CalculatorLink({
  calculator,
  savings,
}: {
  calculator: CalculatorBlockId
  savings?: number | null
}): React.ReactNode {
  return (
    <Link href={`/calculator?calculator=${calculator}`} className={OUTER_CLASS}>
      <Inner calculator={calculator} savings={savings} />
    </Link>
  )
}

export function CalculatorButton({
  calculator,
  onClick,
}: {
  calculator: CalculatorBlockId
  onClick: () => void
}): React.ReactNode {
  return (
    <button onClick={onClick} className={OUTER_CLASS}>
      <Inner calculator={calculator} />
    </button>
  )
}

function Inner({
  calculator,
  savings,
}: {
  calculator: CalculatorBlockId
  savings?: number | null
}): React.ReactNode {
  const { background, icon, title } = CALCULATOR_PROPERTIES[calculator]
  return (
    <div
      className="flex flex-col"
    >
      <div className={`background ${background} w-full text-white flex flex-row justify-between items-center h-[55px] px-4`}>
        <Icon icon={icon} height={32} width={32} />
        {savings != null ? <p><b>{formatNumber(savings, 'currency')}</b></p> : <div />}
      </div>
      <div className="flex flex-row justify-between items-center text-sm bg-white w-full h-[55px] px-4">
        <p>{title}</p>
        <Icon icon="rightArrowDark" height={15} width={15} />
      </div>
    </div>
  )
}
