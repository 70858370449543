import Image from 'next/image'
import { publicUrl } from './public'

const ICONS = {
  airConditioner: '/icons/air-conditioner-outline.svg',
  calendar: '/icons/calendar.svg',
  close: '/icons/close.svg',
  closeWhite: '/icons/close-white.svg',
  coins: '/icons/coins.svg',
  down: '/icons/down.svg',
  energybill: '/icons/energybill.svg',
  ev: '/icons/ev-outline.svg',
  facebook: '/icons/facebook.svg',
  filter: '/icons/filter.svg',
  funding: '/icons/funding.svg',
  gasburner: '/icons/gasburner.svg',
  heatpump: '/icons/heatpump.svg',
  house: '/icons/house.svg',
  houseOutline: '/icons/house-outline.svg',
  instagram: '/icons/instagram.svg',
  instagramBrand: '/icons/instagram-brand.svg',
  leftArrowDark: '/icons/left-arrow-dark.svg',
  lightningbolt: '/icons/lightningbolt.svg',
  loftinsulation: '/icons/loftinsulation.svg',
  mail: '/icons/mail.svg',
  money: '/icons/money.svg',
  menu: '/icons/menu.svg',
  monitor: '/icons/monitor.svg',
  orangutan: '/icons/orangutan.svg',
  phone: '/icons/phone.svg',
  place: '/icons/place.svg',
  playCircle: '/icons/play-circle.svg',
  radiator: '/icons/radiator.svg',
  radiatorOutline: '/icons/radiator-outline.svg',
  rightArrowDark: '/icons/right-arrow-dark.svg',
  rightArrowLight: '/icons/right-arrow-light.svg',
  routine: '/icons/routine-outline.svg',
  mcs: '/icons/mcs.webp',
  snowflake: '/icons/snowflake.svg',
  spanner: '/icons/spanner.svg',
  solar: '/icons/solar-outline.svg',
  thermostat: '/icons/thermostat.svg',
  tiktok: '/icons/tiktok.svg',
  wallinsulation: '/icons/wallinsulation.svg',
  watertank: '/icons/watertank.svg',
  window: '/icons/window.svg',
  windsock: '/icons/windsock.svg',
}

export default function Icon({
  icon,
  width,
  height,
}: {
  icon: keyof typeof ICONS
  width?: number
  height?: number
}): React.ReactNode {
  return (
    <Image
      src={publicUrl(ICONS[icon])}
      width={width ?? 24}
      height={height ?? 24}
      alt={icon}
    />
  )
}
